<template>
  <div>
    <v-layout v-for="(item, i) in reservationData" :key="i" wrap>
      <v-flex xs12 md12 pt-4>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2>
                  <v-img
                    v-if="item.slotDetail.slot.programme.photos.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + item.slotDetail.slot.programme.photos[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                    src="../../../assets/images/home.jpg"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span class="itemCaption" style="font-size: 18px">
                        {{ item.slotDetail.slot.programme.progName }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(item.bookingDate) }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Slot</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ formatTime(item.slotDetail.startTime) }} -
                        {{ formatTime(item.slotDetail.endTime) }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left>
                      <span
                        v-if="item.slotDetail.slot.programme.isCottage"
                        class="textField2"
                      >
                        Rooms Reserved</span
                      >
                      <span v-else class="textField2"> Seats Reserved</span
                      ><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.reserved }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageLoader from "@/components/Common/imageLoader";

export default {
  props: ["fromDate", "toDate", "programme"],
  // props: ["reservationData","pages"],
  components: {
    ImageLoader,
  },
  data() {
    return {
      currentPage: 1,
      pages: 0,
      reservationData: [],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  watch: {
    fromDate() {
      this.getReservations();
    },
    toDate() {
      this.getReservations();
    },
    programme() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getReservations();
    },
    currentPage() {
      this.getReservations();
    },
  },
  beforeMount() {
    this.getReservations();
  },
  methods: {
    winStepper() {
      // console.log("step", this.currentPage);
      this.$emit("stepper", {
        getReservations: true,
        type: "Reservation",
        // currentPage: this.currentPage,
        // pages: this.pages,
      });
    },

    getReservations() {
      // alert("Compomnet getResrev")
      this.winStepper();
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/booking/bookingreservations",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          programme: this.programme,
          slotDetail: this.slotDetail,
          count: 6,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.bookingData = response.data.data;
            this.reservationData = response.data.data;
            this.pages = response.data.pages;

            // this.excel = response.data.data;
            // this.json_data = this.excel;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};

// export default {
//   props: ["reservationData"],
//   components: {
//     ImageLoader,
//   },
//    methods:{
//        formatDate(item) {
//       var dt = new Date(item);
//       var year = dt.getFullYear();
//       dt = dt.toString();
//       var strTime = dt.slice(4, 10) + " " + year;
//       return strTime;
//     },
//     formatTime(item) {
//       if (!item) return;
//       var hours = Number(item.split(":")[0]);
//       var minutes = Number(item.split(":")[1]);
//       var ampm = hours >= 12 ? "PM" : "AM";
//       hours = hours % 12;
//       hours = hours ? hours : 12;
//       minutes = minutes < 10 ? "0" + minutes : minutes;
//       var strTime = hours + ":" + minutes + " " + ampm;
//       return strTime;
//     },
//   }
// };
</script>